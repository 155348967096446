import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISocial {
id:number,
title:string,
day_of?:string,
picture?:string,
place?:string,
description?:string,
show_event?:boolean
}

interface ISocialData {
    list?: Array<ISocial>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ISocialData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const SocialSlice = createSlice({
    name: "Social",
    initialState,
    reducers: {
        setSocialList: (state, _action: PayloadAction<ISocialData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetSocialToInit: (state) => {
            state = initialState;
        },
        setSocialMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setSocialList, resetSocialToInit, setSocialMessage } = SocialSlice.actions;

export default SocialSlice.reducer;

