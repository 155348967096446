import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAre_Method {
id:number,
header:string,
description:string,
section_image?:string
}

interface IAre_MethodData {
    list?: Array<IAre_Method>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IAre_MethodData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const are_methodSlice = createSlice({
    name: "are_method",
    initialState,
    reducers: {
        setAre_MethodList: (state, _action: PayloadAction<IAre_MethodData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAre_MethodToInit: (state) => {
            state = initialState;
        },
        setAre_MethodMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAre_MethodList, resetAre_MethodToInit, setAre_MethodMessage } = are_methodSlice.actions;

export default are_methodSlice.reducer;

