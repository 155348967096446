import { APIService } from "services";

export const getSocial = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllSocial(pageNo,pageSize);
    }
    else{
        try {
            res = await searchSocial(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addSocial = (data) => {
return APIService.api().post(`/Social`,data)
}
export const updateSocial = (id,data) => {
return APIService.api().patch(`/Social/${id}`,data)
}
export const getAllSocial = (pageNo,pageSize) => {
return APIService.api().get(`/Social/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneSocial = (id) => {
return APIService.api().get(`/Social/${id}`)
}
export const searchSocial = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/Social/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteSocial = (id) => {
return APIService.api().delete(`/Social/${id}`)
}
