import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import are_method from "redux/slices/are_method";
import Social from "redux/slices/Social";
import Projects from "redux/slices/Projects";
import About from "redux/slices/About";


const rootReducer = combineReducers({ template,authToken,are_method,Social,Projects,About });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

