import { useFormik } from "formik";
import React, { useState } from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setAre_MethodMessage } from "redux/actions";
import { uploadFileService } from "services/fileUploadService";
import { useAppDispatch } from "redux/store";
import { addAre_Method, updateAre_Method } from "services/are_methodService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Are_MethodForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const [message, setMessage] = useState('')
    const dispatch = useAppDispatch();
    const iValue = { id: 0, header: '', description: '', section_image: '', fileData: [] };
    const initialValue = action === 'edit' ? row : iValue;


    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                if (Array.isArray(values.fileData) && values.fileData.length > 0) {
                    var formData = new FormData();
                    formData.append("file", values.fileData[0]);
                    uploadFileService(formData).then((res: any) => {
                        if (res && res.data && res.data.document) {
                            values.section_image = res.data.document
                            updData(values)
                        } else {
                            setMessage('Failed to upload file');
                        }
                    })
                } else {
                    updData(values)
                }
            } else if (action === 'add') {
                if (Array.isArray(values.fileData) && values.fileData.length > 0) {
                    var formDataAdd = new FormData();
                    formDataAdd.append("file", values.fileData[0]);
                    uploadFileService(formDataAdd).then((res: any) => {
                        if (res && res.data && res.data.document) {
                            values.section_image = res.data.document
                            addData(values)
                        } else {
                            setMessage('Failed to upload file');
                        }
                    })
                } else {
                    addData(values)
                }
            }
        },
        validationSchema: yup.object({
            header: yup.string().required('header is required'),
            description: yup.string().required('description is required'),
            section_image: yup.string().nullable(),
        }),

    });
    async function addData(values) {
        const { fileData, ...newObject } = values;
        console.log("values:", newObject);
        const response = await addAre_Method(newObject);
        if (response && (response.status === 200 || response.status === 201)) {
            dispatch(setAre_MethodMessage("Added Successfully"));
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            hideShowForm('');
        } else {
            dispatch(setAre_MethodMessage("Some error occurred!"));
        }
    }
    async function updData(values) {
        const { fileData, ...newObject } = values;
        console.log("updDate:", newObject);
        const response = await updateAre_Method(row.id, newObject);
        if (response && (response.status === 200 || response.status === 201)) {
            dispatch(setAre_MethodMessage("Updated Successfully"));
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            hideShowForm('');
        } else {
            dispatch(setAre_MethodMessage("Some error occurred!"));
        }
    }
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} השיטה שלנו
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">הצג את השיטות</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        <label className="form -control-label">כותרת</label>
                        <Form.Control type="text" name="header" className="form-control" value={formik.values.header}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.header && !!formik.errors.header}
                            isValid={!!formik.touched.header && !formik.errors.header}
                        ></Form.Control>
                        {
                            formik.errors.header && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.header}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">תיאור</label>
                        <Form.Control type="text" name="description" className="form-control" value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.description && !!formik.errors.description}
                            isValid={!!formik.touched.description && !formik.errors.description}
                        ></Form.Control>
                        {
                            formik.errors.description && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.description}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">תמונה</label>
                        <Form.Control type="text" name="section_image" className="form-control" value={formik.values.section_image || message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.section_image && !!formik.errors.section_image}
                            isValid={!!formik.touched.section_image && !formik.errors.section_image}
                        ></Form.Control>
                        {
                            formik.errors.section_image && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.section_image}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload png | jpg | jpeg files</Form.Label>
                        <Form.Control type="file"
                            onChange={(event: React.ChangeEvent) => {
                                const dFile = event.target as HTMLInputElement;
                                if (dFile.files && dFile.files[0]) {
                                    formik.setFieldValue("fileData", Array.from(dFile.files));
                                }
                            }}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.fileData && !!formik.errors.fileData}
                            isValid={!!formik.touched.fileData && !formik.errors.fileData}
                        />
                        {
                            formik.errors.fileData && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.fileData}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">שמור</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

