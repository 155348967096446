import { useFormik } from "formik";
import React from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setAboutMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addAbout, updateAbout } from "services/AboutService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const AboutForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:0,page_header:'',short_description:'',description:'',picture:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateAbout(row.id,values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setAboutMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setAboutMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addAbout(values);
               if (response && (response.status === 200 || response.status === 201)) {
                    dispatch(setAboutMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setAboutMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           page_header: yup.string().nullable(),
short_description: yup.string().required('short_description is required'),
description: yup.string().nullable(),
picture: yup.string().nullable(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} About
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View About</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">page_header</label>
<Form.Control type="text" name="page_header" className="form-control" value={formik.values.page_header}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.page_header && !!formik.errors.page_header}
isValid ={!!formik.touched.page_header && !formik.errors.page_header}
></Form.Control>
{
    formik.errors.page_header && (
    <Form.Control.Feedback type="invalid">
        <>{formik.errors.page_header}</>
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">short_description</label>
<Form.Control type="text" name="short_description" className="form-control" value={formik.values.short_description}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.short_description && !!formik.errors.short_description}
isValid ={!!formik.touched.short_description && !formik.errors.short_description}
></Form.Control>
{
    formik.errors.short_description && (
    <Form.Control.Feedback type="invalid">
        <>{formik.errors.short_description}</>
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">description</label>
<Form.Control type="text" name="description" className="form-control" value={formik.values.description}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.description && !!formik.errors.description}
isValid ={!!formik.touched.description && !formik.errors.description}
></Form.Control>
{
    formik.errors.description && (
    <Form.Control.Feedback type="invalid">
        <>{formik.errors.description}</>
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">picture</label>
<Form.Control type="text" name="picture" className="form-control" value={formik.values.picture}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.picture && !!formik.errors.picture}
isValid ={!!formik.touched.picture && !formik.errors.picture}
></Form.Control>
{
    formik.errors.picture && (
    <Form.Control.Feedback type="invalid">
        <>{formik.errors.picture}</>
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

