import { APIService } from "services";

export const getAre_Method = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllAre_Method(pageNo,pageSize);
    }
    else{
        try {
            res = await searchAre_Method(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addAre_Method = (data) => {
return APIService.api().post(`/are_method`,data)
}
export const updateAre_Method = (id,data) => {
return APIService.api().patch(`/are_method/${id}`,data)
}
export const getAllAre_Method = (pageNo,pageSize) => {
return APIService.api().get(`/are_method/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneAre_Method = (id) => {
return APIService.api().get(`/are_method/${id}`)
}
export const searchAre_Method = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/are_method/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteAre_Method = (id) => {
return APIService.api().delete(`/are_method/${id}`)
}
