import { APIService } from "services";

export const getProjects = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllProjects(pageNo,pageSize);
    }
    else{
        try {
            res = await searchProjects(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data){
    return res.data;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const addProjects = (data) => {
return APIService.api().post(`/Projects`,data)
}
export const updateProjects = (id,data) => {
return APIService.api().patch(`/Projects/${id}`,data)
}
export const getAllProjects = (pageNo,pageSize) => {
return APIService.api().get(`/Projects/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const getOneProjects = (id) => {
return APIService.api().get(`/Projects/${id}`)
}
export const searchProjects = (searchKey,pageNo,pageSize) => {
return APIService.api().get(`/Projects/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`)
}
export const deleteProjects = (id) => {
return APIService.api().delete(`/Projects/${id}`)
}
