import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAbout {
id:number,
page_header?:string,
short_description:string,
description?:string,
picture?:string
}

interface IAboutData {
    list?: Array<IAbout>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IAboutData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const AboutSlice = createSlice({
    name: "About",
    initialState,
    reducers: {
        setAboutList: (state, _action: PayloadAction<IAboutData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAboutToInit: (state) => {
            state = initialState;
        },
        setAboutMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAboutList, resetAboutToInit, setAboutMessage } = AboutSlice.actions;

export default AboutSlice.reducer;

