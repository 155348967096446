import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
interface Props {
    children: React.ReactNode;
  }
const Layout: React.FC<Props> = ({ children }) => {
    return (
        <div id="wrapper">
            <Sidebar></Sidebar>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar></Topbar>
                    {children}
                </div>
                <Footer></Footer>
            </div>
        </div>
    );
};

export default Layout;


