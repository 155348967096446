import { useFormik } from "formik";
import React, { useState } from "react";

import { Button, Card, Form } from "react-bootstrap";
import { setSocialMessage } from "redux/actions";
import { uploadFileService } from "services/fileUploadService";
import { useAppDispatch } from "redux/store";
import { addSocial, updateSocial } from "services/SocialService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const SocialForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const [message, setMessage] = useState('')
    const dispatch = useAppDispatch();
    const iValue = { id: 0, title: '', day_of: '', picture: '', place: '', description: '', show_event: 1, fileData: [] };
    const initialValue = action === 'edit' ? row : iValue;


    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                if (Array.isArray(values.fileData) && values.fileData.length > 0) {
                    var formData = new FormData();
                    formData.append("file", values.fileData[0]);
                    uploadFileService(formData).then((res: any) => {
                        if (res && res.data && res.data.document) {
                            values.picture = res.data.document
                            updData(values)
                        } else {
                            setMessage('Failed to upload file');
                        }
                    })
                } else {
                    updData(values)
                }
            } else if (action === 'add') {
                if (Array.isArray(values.fileData) && values.fileData.length > 0) {
                    var formDataAdd = new FormData();
                    formDataAdd.append("file", values.fileData[0]);
                    uploadFileService(formDataAdd).then((res: any) => {
                        if (res && res.data && res.data.document) {
                            values.picture = res.data.document
                            addData(values)
                        } else {
                            setMessage('Failed to upload file');
                        }
                    })
                } else {
                    addData(values)
                }
            }
        },
        validationSchema: yup.object({
            title: yup.string().required('title is required'),
            day_of: yup.string().nullable(),
            picture: yup.string().nullable(),
            place: yup.string().nullable(),
            description: yup.string().nullable(),
            show_event: yup.boolean().nullable(),

        }),
    });
    async function addData(values) {
        const { fileData, ...newObject } = values;
        console.log("values:", newObject);
        const response = await addSocial(newObject);
        if (response && (response.status === 200 || response.status === 201)) {
            dispatch(setSocialMessage("Added Successfully"));
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            hideShowForm('');
        } else {
            dispatch(setSocialMessage("Some error occurred!"));
        }
    }
    async function updData(values) {
        const { fileData, ...newObject } = values;
        console.log("updDate:", newObject);
        const response = await updateSocial(row.id, newObject);
        if (response && (response.status === 200 || response.status === 201)) {
            dispatch(setSocialMessage("Updated Successfully"));
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            hideShowForm('');
        } else {
            dispatch(setSocialMessage("Some error occurred!"));
        }
    }
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Social
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">קשרי קהילה</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group>
                        <label className="form -control-label">כותרת</label>
                        <Form.Control type="text" name="title" className="form-control" value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.title && !!formik.errors.title}
                            isValid={!!formik.touched.title && !formik.errors.title}
                        ></Form.Control>
                        {
                            formik.errors.title && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.title}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">יום האירוע</label>
                        <Form.Control type="text" name="day_of" className="form-control" value={formik.values.day_of}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.day_of && !!formik.errors.day_of}
                            isValid={!!formik.touched.day_of && !formik.errors.day_of}
                        ></Form.Control>
                        {
                            formik.errors.day_of && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.day_of}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">תמונה</label>
                        <Form.Control type="text" name="picture" className="form-control" value={formik.values.picture}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.picture && !!formik.errors.picture}
                            isValid={!!formik.touched.picture && !formik.errors.picture}
                        ></Form.Control>
                        {
                            formik.errors.picture && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.picture}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">place</label>
                        <Form.Control type="text" name="place" className="form-control" value={formik.values.place}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.place && !!formik.errors.place}
                            isValid={!!formik.touched.place && !formik.errors.place}
                        ></Form.Control>
                        {
                            formik.errors.place && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.place}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">תיאור</label>
                        <Form.Control type="text" name="description" className="form-control" value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.description && !!formik.errors.description}
                            isValid={!!formik.touched.description && !formik.errors.description}
                        ></Form.Control>
                        {
                            formik.errors.description && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.description}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <label className="form -control-label">הצג אירוע</label>
                        <Form.Control type="number" name="show_event" className="form-control" value={formik.values.show_event}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.show_event && !!formik.errors.show_event}
                            isValid={!!formik.touched.show_event && !formik.errors.show_event}
                        ></Form.Control>
                        {
                            formik.errors.show_event && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.show_event}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload png | jpg | jpeg files</Form.Label>
                        <Form.Control type="file"
                            onChange={(event: React.ChangeEvent) => {
                                const dFile = event.target as HTMLInputElement;
                                if (dFile.files && dFile.files[0]) {
                                    formik.setFieldValue("fileData", Array.from(dFile.files));
                                }
                            }}
                            onBlur={formik.handleBlur}
                            isInvalid={!!formik.touched.fileData && !!formik.errors.fileData}
                            isValid={!!formik.touched.fileData && !formik.errors.fileData}
                        />
                        {
                            formik.errors.fileData && (
                                <Form.Control.Feedback type="invalid">
                                    <>{formik.errors.fileData}</>
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

