import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IProjects {
id:number,
project?:string,
address?:string,
description?:string,
proj_image?:string
}

interface IProjectsData {
    list?: Array<IProjects>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IProjectsData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const ProjectsSlice = createSlice({
    name: "Projects",
    initialState,
    reducers: {
        setProjectsList: (state, _action: PayloadAction<IProjectsData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetProjectsToInit: (state) => {
            state = initialState;
        },
        setProjectsMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setProjectsList, resetProjectsToInit, setProjectsMessage } = ProjectsSlice.actions;

export default ProjectsSlice.reducer;

